import $ from "jquery";
import {cloneElement} from './util';
import anime from 'animejs';

var playList = [];
var pauseList = [];

var robotLineAnime = anime({
  targets: ['.simulation-box .robot-line-left', '.simulation-box .robot-line-right'],
  translateY: ['10px', 0, '-10px', 0],
  delay: function (el) {
    return $(el).data('delay') || 0;
  },
  duration: 6000,
  easing: 'linear',
  loop: true,
  autoplay: false,
});
playList.push(robotLineAnime);

var boxLineAnime = anime({
  targets: ['.simulation-box .robot-line-left', '.simulation-box .robot-line-right'],
  translateY: ['10px', 0, '-10px', 0],
  delay: function (el) {
    return $(el).data('delay') || 0;
  },
  duration: 6000,
  easing: 'linear',
  loop: true,
  autoplay: false,
});
playList.push(boxLineAnime);

var TLBallList = [];
cloneElement('.simulation-box .ball', 20, function (el, index) {
  var TLBall = anime.timeline({
    targets: el,
    easing: 'linear',
    direction: 'normal',
    autoplay: false,
    loop: 1,
  });

  TLBall.add({
    translateX: '-3vw',
    translateY: '-7.8vw',
    duration: 0,
    easing: 'linear',
    opacity: function (el, i) {
      return 0.7 - 0.04 * index;
    },
    delay: function () {
      return index * 15
    }
  }).add({
    translateX: '1.3vw',
    translateY: '-5.2vw',
    duration: 400,
  }).add({
    rotate: 80,
    translateX: '-=2.2vw',
    translateY: '+=3.5vw',
    duration: 300,
  }).add({
    rotate: 80,
    translateX: '+=20vw',
    translateY: '+=44vw',
    duration: 3000,
  });

  TLBallList.push(TLBall);
});

function playBall() {
  for (var i = 0; i < TLBallList.length; i++) {
    TLBallList[i].restart();
  }
}

var robotDarkBackFrameAnime;

var robotDarkFrameAnime = anime({
  targets: ['.simulation-box .robot-dark', '.simulation-box .frame.pos1'],
  opacity: [0.6, 1],
  translateX: ['5vw', '-1vw'],
  translateY: ['-3vw', '1vw'],
  scale: [0.9, 1],
  duration: 1500,
  delay: 0,
  easing: 'linear',
  loop: true,
  autoplay: false,
  update: function (e) {
    if (e.progress === 100) {
      if (robotDarkBackFrameAnime) {
        robotDarkBackFrameAnime.restart();
      } else {
        robotDarkBackFrameAnime = anime({
          targets: ['.simulation-box .robot-dark-back', '.simulation-box .frame.pos2'],
          scale: [1, 0.9],
          translateX: ['0', '-7vw'],
          translateY: ['0', '-4vw'],
          duration: 1500,
          easing: 'linear',
          direction: 'normal',
          loop: 1,
        });
        pauseList.push(robotDarkBackFrameAnime);
      }
    }
  }
});

playList.push(robotDarkFrameAnime);


var robotLightAnime = anime({
  targets: '.simulation-box .robot-light',
  translateX: ['4vw', '-10vw'],
  translateY: ['-2vw', '5.5vw'],
  duration: 2500,
  easing: 'linear',
  loop: true,
  autoplay: false,
});
playList.push(robotLightAnime);

// cloneElement('.simulation-box .robot-light', 2, function (el, i) {
  // var TLRobotLight = anime.timeline({
  //   targets: [el],
  //   easing: 'linear',
  //   direction: 'normal',
  //   loop: true,
  //   autoplay: false,
  // });
  //
  // TLRobotLight.add({
  //   opacity: [0, 1],
  //   duration: 200,
  //   delay: function () {
  //     return i * 1500;
  //   },
  // }).add({
  //   translateX: ['4vw', '-10vw'],
  //   translateY: ['-2vw', '5.5vw'],
  //   duration: 3800,
  //   easing: 'linear',
  //   loop: true,
  // });
  //
  // playList.push(TLRobotLight);
// });

function cloneDot(selector) {
  cloneElement(selector, 10, function (el, i) {
    function getRandomX(count) {
      var arr = [];

      while (count--) {
        arr.push(Math.random() * 4 - 2 + 'vw');
      }

      return arr;
    }

    var num = Math.random();

    var animeDot = anime({
      targets: el,
      duration: 20000,
      scale: num * 2,
      opacity: num,
      translateY: '-15vh',
      translateX: getRandomX(10),
      translateZ: getRandomX(10),
      easing: 'linear',
      loop: true,
      offset: -16000,
      autoplay: false,
      delay: function () {
        return i * 1000 + Math.random() * 400;
      }
    });

    playList.push(animeDot);
  });
}


cloneDot('.simulation-box .blue-dot.pos1');
cloneDot('.simulation-box .blue-dot.pos2');

pauseList = playList;

export function play() {
  playBall();
  playList.map(function (item) {
    item.restart();
  });
}

export function pause() {
  pauseList.map(function (item) {
    item.pause();
  });
}


