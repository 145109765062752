import $ from "jquery";

/**
 * 克隆元素
 * @param el 元素
 * @param count 克隆的数量
 * @param callback 每克隆一个执行的回调
 * @returns {Array} 克隆后端元素列表
 */
export function cloneElement(el, count, callback) {
  var $el = $(el);

  var $clonedList = [];
  for (var i = 0; i < count; i++) {
    var cloneEl = $el.clone()[0];
    $el.after(cloneEl);
    $clonedList.push(cloneEl);

    callback && callback(cloneEl, i);
  }

  return $clonedList;
}
