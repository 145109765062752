import $ from "jquery";
import {cloneElement} from './util';
import anime from 'animejs';

var playList = [];
var pauseList = [];

var animeFirst = anime({
  targets: [
    '.cloud-box .floor-lg',
    '.cloud-box .cubes-lg',
    '.cloud-box .robot-floor',
    '.cloud-box .robot-dark',
    '.cloud-box .floor-sm-dark',
    '.cloud-box .floor-sm-light',
    '.cloud-box .robot-light',
    '.cloud-box .cubes-sm',
  ],
  opacity: [0, 1],
  duration: 600,
  autoplay: false,
  translateY: function (el) {
    if (el.className.indexOf('floor') >= 0) {
      return 0;
    } else {
      return [-Math.random() * 40 + 'vw', 0];
    }
  },
  delay: function (el, i) {
    if (el.className.indexOf('floor') >= 0) {
      return i * 100;
    } else {
      return 1000 + i * 100;
    }
  },
  easing: 'linear',
  complete: function () {
    var robotFloorLightAnime = anime({
      targets: '.cloud-box .robot-floor-light',
      opacity: [0, 1],
      duration: 1000,
      direction: 'alternate',
      easing: 'linear',
      loop: true,
    });

    var robotFloorDarkAnime = anime({
      targets: '.cloud-box .robot-floor-dark',
      opacity: [1, 0],
      duration: 1000,
      direction: 'alternate',
      easing: 'linear',
      loop: true,
    });

    var floorSmLightAnime = anime({
      targets: '.cloud-box .floor-sm-light',
      opacity: [1, 0],
      duration: 1000,
      direction: 'alternate',
      easing: 'linear',
      loop: true,
    });

    pauseList.push(robotFloorLightAnime, robotFloorDarkAnime, floorSmLightAnime);
  }
});



function cloneDot(selector) {
  cloneElement(selector, 25, function (el, i) {
    function getRandomX(count) {
      var arr = [];

      while (count--) {
        arr.push(Math.random() * 4 - 2 + 'vw');
      }

      return arr;
    }

    var num = Math.random();

    var dotAnime = anime({
      targets: el,
      duration: 20000,
      scale: num * 1.6,
      opacity: num,
      translateY: '-40vh',
      translateX: getRandomX(10),
      translateZ: getRandomX(10),
      easing: 'linear',
      loop: true,
      offset: -10000,
      autoplay: false,
      delay: function () {
        return i * 1000 + Math.random() * 400;
      }
    });

    playList.push(dotAnime);
  });
}

cloneDot('.cloud-box .blue-dot.pos1');
cloneDot('.cloud-box .blue-dot.pos2');

playList.push(animeFirst);
pauseList = pauseList.concat(playList);

export function play() {
  playList.map(function (item) {
    item.restart();
  });
}

export function pause() {
  pauseList.map(function (item) {
    item.pause();
  });
}

