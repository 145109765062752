import $ from "jquery";
import {cloneElement} from './util';
import anime from 'animejs';


function getRandomElement(selector) {
  var elList = document.querySelectorAll(selector);
  return elList[~~(Math.random() * elList.length)];
}

var playList = [];
var pauseList = [];

var count = 0;

function playSquareLine() {
  var el = getRandomElement('.process-box .square-line');
  var elList = cloneElement(el, 5);

  var TLSquareLine = anime.timeline({
    targets: elList,
    duration: 1000,
    easing: 'linear',
    direction: 'normal',
    loop: false,
  });

  TLSquareLine.add({
    scale: [0.2, 1.3],
    opacity: [1, 0],
    delay: function (el, i) {
      return i * 100;
    },
    duration: 1000,
    easing: 'linear',
  });

  TLSquareLine.finished.then(function () {
    $(elList).remove();
    count++ % 2 === 0 ? playLine1() : playLine2();
  });

  TLSquareLine.play();
}

function playLine1() {
  var TLLine1 = anime.timeline({
    targets: '.process-box .arrow-1',
    delay: 0,
    easing: 'linear',
    direction: 'normal',
  });

  TLLine1.add({
    opacity: 0,
    duration: 0,
  }).add({
    opacity: 1,
    duration: 10,
    rotate: 90,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    rotate: 90,
    duration: 300,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    rotate: 180,
    duration: 150,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    translateX: '-6.7vw',
    rotate: 180,
    duration: 600,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    translateX: '-6.7vw',
    rotate: 90,
    duration: 150,
  }).add({
    opacity: 1,
    translateY: '5vw',
    translateX: '-6.7vw',
    rotate: 90,
    duration: 300,
  }).add({
    opacity: 0,
    duration: 10,
  });

  TLLine1.finished.then(playLeftBox);
}

function playLine2() {
  var TLLine2 = anime.timeline({
    targets: '.process-box .arrow-2',
    delay: 0,
    easing: 'linear',
    direction: 'normal',
    loop: false,
  });

  TLLine2.add({
    opacity: 1,
    duration: 10,
    rotate: 90,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    duration: 300,
    rotate: 90,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    rotate: 0,
    duration: 150,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    translateX: '10.2vw',
    duration: 900,
    rotate: 0,
  }).add({
    opacity: 1,
    translateY: '1.9vw',
    translateX: '10.2vw',
    rotate: 90,
    duration: 150,
  }).add({
    opacity: 1,
    translateY: '5vw',
    translateX: '10.1vw',
    duration: 300,
    rotate: 90,
  }).add({
    opacity: 0,
    duration: 10,
  });

  TLLine2.finished.then(playRightBox);
}

function playLine3() {
  var TLLine3 = anime.timeline({
    targets: '.process-box .arrow-3',
    delay: 0,
    easing: 'linear',
    direction: 'normal',
    loop: false,
  });

  TLLine3.add({
    opacity: 1,
    duration: 10,
    translateX: 0,
  }).add({
    opacity: 1,
    translateX: '8vw',
    duration: 990,
  }).add({
    opacity: 0,
    duration: 10,
  });

  TLLine3.finished.then(playRightBox);
}

function playLeftBox() {
  var TLSquareLeftBox = anime.timeline({
    targets: getRandomElement('.process-box .square-box.left'),
    delay: 0,
    direction: 'normal',
    easing: 'easeInOutCubic',
    loop: false,
  });

  TLSquareLeftBox.add({
    opacity: [0, 1, 0],
    duration: 2000,
  });

  TLSquareLeftBox.finished.then(playLine3);
}

function playRightBox() {
  var TLSquareRightBox = anime.timeline({
    targets: getRandomElement('.process-box .square-box.right'),
    delay: 0,
    direction: 'normal',
    easing: 'easeInOutCubic',
    loop: false,
  });

  TLSquareRightBox.add({
    opacity: [0, 1, 0],
    duration: 2000,
  });
}


// TLSquareLine.play();
//
// setInterval(TLSquareLine.restart, 2000);
//
// window.tl = TLSquareLine;
//
// var promise = TLSquareLine.finished.then(start);
//
// function start() {
//   Math.random() > 0.5 ? TLLine1.restart() : TLLine2.restart();
//
//   // setTimeout(function () {
//   //   promise = TLSquareLine.finished.then(start);
//   // });
// }
//
// TLLine1.finished.then(TLSquareLeftBox.restart);
// TLSquareLeftBox.finished.then(TLLine3.restart);
// TLLine3.finished.then(TLSquareRightBox.restart);
//
// TLLine2.finished.then(TLSquareRightBox.restart);


var timer;

export function play() {
  playSquareLine();
  timer = setInterval(playSquareLine, 1500);
}

export function pause() {
  clearInterval(timer);
}
