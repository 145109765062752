import $ from "jquery";
import {cloneElement} from './util';
import anime from 'animejs';

var smallCubes = cloneElement('.monitor-box .cube-sm', 20);

var playList = [];
var pauseList = [];

var smallCubesAnime = anime({
  targets: smallCubes,
  scale: [0.5, 1.5],
  translateX: '75vw',
  translateY: '100vh',
  delay: function (el, i) {
    return i * 2500;
  },
  duration: 10000,
  offset: -10000,
  easing: 'linear',
  loop: true,
  autoplay: false,
});

var cubeLgRightAnime = anime({
  targets: ['.monitor-box .cube-lg-right'],
  translateY: '-150vh',
  opacity: [0, 1],
  delay: function (el, i) {
    return i * 5000;
  },
  duration: 10000,
  easing: 'linear',
  offset: -10000,
  loop: true,
  autoplay: false,
});

var cubeLgLeftAnime = anime({
  targets: ['.monitor-box .cube-lg-left'],
  translateY: '-150vh',
  opacity: [0, 1],
  delay: function (el, i) {
    return 2500 + i * 5000;
  },
  duration: 10000,
  easing: 'linear',
  offset: -10000,
  loop: true,
  autoplay: false,
});

var floorAnime = anime({
  targets: ['.monitor-box .floor'],
  translateY: ['10vw', 0],
  opacity: [0, 1],
  delay: function (el, i) {
    return 0;
  },
  duration: 1000,
  easing: 'linear',
  autoplay: false,
});

var panelLeftAnime = anime({
  targets: ['.monitor-box .panel-left'],
  translateX: ['-10vw', 0],
  translateY: ['-10vw', 0],
  opacity: [0, 1],
  duration: 1000,
  easing: 'linear',
  loop: false,
  autoplay: false,
});

var panelRightAnime = anime({
  targets: ['.monitor-box .panel-right'],
  translateX: ['10vw', 0],
  translateY: ['-10vw', 0],
  opacity: [0, 1],
  delay: 0,
  duration: 1000,
  easing: 'linear',
  loop: false,
  autoplay: false,
});

var lineWaveAnime;
var panelAnime;

var panelBottomAnime = anime({
  targets: ['.monitor-box .panel-bottom'],
  translateX: ['10vw', 0],
  translateY: ['10vw', 0],
  opacity: [0, 1],
  delay: 0,
  duration: 1000,
  easing: 'linear',
  loop: false,
  autoplay: false,
  complete: function () {

    if (!panelAnime) {
      panelAnime = anime({
        targets: ['.monitor-box .panel'],
        translateY: ['10px', 0, '-10px', 0],
        delay: function (el) {
          return $(el).data('delay') || 0;
        },
        duration: 6000,
        easing: 'linear',
        loop: true,
      });

      pauseList.push(panelAnime);
    } else {
      panelAnime.restart();
    }

    if (!lineWaveAnime) {
      lineWaveAnime = anime({
        targets: ['.monitor-box .line-wave'],
        translateX: '-6vw',
        translateY: '3vw',
        translateZ: '4vw',
        duration: 3000,
        easing: 'linear',
        loop: true,
      });

      pauseList.push(panelAnime, lineWaveAnime);
    } else {
      lineWaveAnime.restart();
    }
  }
});

var manAnime = anime({
  targets: ['.monitor-box .man'],
  translateY: ['-10px', 0],
  opacity: [0, 1],
  delay: 1000,
  duration: 1000,
  easing: 'linear',
  autoplay: false,
});


playList.push(
  smallCubesAnime, cubeLgRightAnime, cubeLgLeftAnime,
  floorAnime, panelLeftAnime, panelRightAnime,
  panelBottomAnime, manAnime
);

pauseList = pauseList.concat(playList);

export function play() {
  playList.map(function (item) {
    item.restart();
  });
}

export function pause() {
  pauseList.map(function (item) {
    item.pause();
  });
}
